import React from 'react';
import { Helmet } from 'react-helmet';

import HeaderCommon from 'sections/ScreverOcxProgram/HeaderCommon';
import SurveyIframe from 'sections/ScreverOcxProgram/SurveyIframe';

import 'sections/ScreverOcxProgram/common.scss';

const FeedbackPage = () => {
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
        title="Screver BTQ Event | Feedback"
      >
        <meta name="description" content="" />

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Epilogue:wght@400;500;600;700;800;900&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      <div className="scr-ocx-program scr-btq-event">
        <HeaderCommon title="Feedback" url="/events/screver-btq-program/" />

        <main>
          <div className="ocx-prog-wrap">
            <SurveyIframe src={'https://go.screver.com/capptoo/btq-feedback'} />
          </div>
        </main>
      </div>
    </>
  );
};

export default FeedbackPage;
